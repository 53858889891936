
















































































































































































































































































































































































































































































































































import Vue from "vue";
import MNotificationVue from "@/mixins/MNotification.vue";
import { jobCostingService } from "@service/job-costing.service";
import { RequestJobCostingConsumeProduct, RequestJobCostingUpdate, ResponseJobCostingDetail } from "@interface/job-costing.interface";
import { Mode } from "@/models/enums/global.enum";
import { IGenericResponsePost } from "@interface/common.interface";
import moment from "moment";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "@constant/date.constant";
import { mapGetters, mapMutations, mapState } from "vuex";
import { ResponseInventoryLineBatch } from "@interface/inventory-line-batch.interface";
import { JOB_COSTING_STATUS } from "@/models/enums/job-costing.enum";
import { fileServices } from "@/services-v2/file.service";
import { transformDataURL } from "@/helpers/file-reader";
import { formatDecimalQty } from "@/helpers/common";
import { Decimal } from "decimal.js-light";


/**
 * this interface will change when view detail
 * to response job costing warehouse pick
 */
interface IBatch extends ResponseInventoryLineBatch {
  key: any;
}

interface IConsumeProduct {
  id: string;
  key: any;
  productCode: string;
  productName: string;
  productId: string;
  locationId: string | null;
  locationName: string | null;
  batchs: IBatch[];
  listBatchPerConsume: RequestJobCostingConsumeProduct[];
}

interface IForm extends ResponseJobCostingDetail {
  displayProduced: {
    key: any;
    id: string;
    alias: string;
    qtyOrder: number;
    warehouseNote: string;
    consumeProducts: IConsumeProduct[];
    listBatch: RequestJobCostingConsumeProduct[];
    processInfo: any[],
    parentProducts: any[];
  }[];
}

interface IModal extends IConsumeProduct {
  idxProduce: number;
}

const ALLOW_CANCEL = [
  JOB_COSTING_STATUS.WAREHOUSE_PICKED,
];

export default Vue.extend({
  name: "JobCostingWarehousePickedV2",
  components: {
    ModalLocationBatch: () => import(/*webpackPrefetch: true */"./modals/ModalLocationBatch.vue"),
    ModalChooseConsumeProduct: () => import(/*webpackPrefetch: true */"./modals/ModalChooseConsumeProduct.vue"),
  },
  mixins: [
    MNotificationVue
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      DEFAULT_TIME_FORMAT,
      rules: {
        operatorName: [{ required: true, trigger: "blur", message: () => this.$t("lbl_validation_required_error") }]
      },
      loading: {
        submit: false,
        cancel: false,
      },
      vmModal: {
        batch: false,
        consume: false,
      },
      form: {
        displayProduced: [],
        ...this.detailJobCosting
      } as IForm,
      jobCostingId: "",
      dtModal: {} as IModal,
      dtModalConsume: {
        parent: {},
        produce: {},
      } as { parent, produce },
      vmModalBatch: "",
    };
  },
  computed: {
    ...mapState({
      reqUpdate: (state: any): RequestJobCostingUpdate => state.jobCostingWarehouseStore.reqUpdate,
      detailJobCosting: (state: any): ResponseJobCostingDetail => state.jobCostingWarehouseStore.detailJobCosting,
    }),
    ...mapGetters({
      getUsername: "authStore/GET_USERNAME"
    }),
    formCol() {
      return {
        labelCol: {
          sm: { span: 24 },
          md: { span: 12 },
        },
        wrapperCol: {
          sm: { span: 24 },
          md: { span: 12 },
        },
      };
    },
    isModeView(): boolean {
      return this.$route.meta.mode === Mode.VIEW;
    },
    isQuotationSubmitted(): boolean {
      return this.form.status === JOB_COSTING_STATUS.QUOTATION_SUBMITTED;
    },
    allowCancel(): boolean {
      return ALLOW_CANCEL.includes(this.form.status as JOB_COSTING_STATUS);
    },
  },
  watch: {
    getUsername(newValue: string): void {
      this.form.operatorName = newValue;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.jobCostingId = this.$route.params.id;
      this.getDetail(this.jobCostingId);
    }
  },
  methods: {
    moment,
    ...mapMutations({
      setDetail: "jobCostingWarehouseStore/SET_DETAIL"
    }),
    getDetailJobCostingWarehouse(jobCostingId: string): Promise<ResponseJobCostingDetail> {
      return jobCostingService.getDetailJobCostingWarehouse(jobCostingId);
    },
    updateJobCostingWarehouse(jobCostingId: string, payload: RequestJobCostingUpdate): Promise<IGenericResponsePost> {
      return jobCostingService.updateJobCostingWarehouse(jobCostingId, payload);
    },
    handleBack(): void {
      this.$router.push({ name: "sales.transactionsales.jobcosting" });
    },
    showModalBatch(record: IConsumeProduct, idxProduce: number): void {
      this.vmModal.batch = true;
      this.dtModal = undefined as any;
      this.dtModal = { ...record, idxProduce };
    },
    handleSubmit(): void {
      const theForm = this.$refs.formWarehousePick as any;
      theForm.validate((valid: boolean) => {
        if (valid && this.validateBatch()) {
          this.update();
        } else {
          this.showNotifValidationError();
        }
      });
    },
    validateBatch(): boolean {
      let i, j;
      for (i = 0; i < this.form.displayProduced.length; i++) {
        const x = this.form.displayProduced[i];
        for (j = 0; j < x.consumeProducts.length; j++) {
          const y = x.consumeProducts[j];
          if (!y.batchs.length) return false;
        }
      }
      return true;
    },
    async update(): Promise<void> {
      try {
        this.loading.submit = true;
        const req = this.reqUpdate;
        req.operatorName = this.form.operatorName || "";
        req.consume = this.form.displayProduced.map(x => {
          const consumeProducts: RequestJobCostingConsumeProduct[] = [];
          x.consumeProducts.forEach(y => {
            y.listBatchPerConsume.forEach(z => {
              consumeProducts.push(z);
            });
          });
          return {
            consumeProducts,
            id: x.id
          };
        });
        const res = await this.updateJobCostingWarehouse(this.jobCostingId, req);
        if (res.message) await this.showInfoModal(res.message);
        this.showSubmitSuccessMesssage();
        this.handleBack();
      } catch (error) {
        this.showSubmitFailedMesssage();
      } finally {
        this.loading.submit = false;
      }
    },
    async getDetail(id: string): Promise<void> {
      try {
        const res = await this.getDetailJobCostingWarehouse(id);
        this.setDetail({...res});
        this.form = {
          ...this.detailJobCosting,
          displayProduced: []
        };
        if (this.form.status === JOB_COSTING_STATUS.QUOTATION_SUBMITTED) {
          this.form.operatorName = this.getUsername;
        }
        if (this.isModeView) {
          this.setDisplayProducedView();
        } else {
          this.setDisplayProduced();
        }
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    setDisplayProducedView(): void {
      this.form.displayProduced = [];
      this.detailJobCosting.consume.forEach((x, i) => {
        // define header for produce product
        const obj = {
          key: i,
          id: x.id,
          alias: x.alias,
          isProcessed: x.isProcessed,
          qtyOrder: x.qtyOrder,
          warehouseNote: x.warehouseNote,
          condition: x.condition,
          invoiceWeight: x.invoiceWeight,
          processInfo: x.processInfo.map((y) => ({...y, thumbnail: null})),
          consumeProducts: [] as any,
          listBatch: [],
          // parentProducts: x.consumeProducts.filter(y => y.parent),
          parentProducts: x.consumeProducts.filter((y, i, self) => i === self.findIndex((me) => me.productId === y.productId)),
        };

        // select distinct consume product
        const ids: {
          productId: string,
          productCode: string,
          productName: string,
        }[] = [];
        x.consumeProducts.forEach(y => {
          // if (!y.parent) {
            if (!ids.find(z => z.productId === y.productId)) {
              ids.push({
                productId: y.productId,
                productCode: y.productCode,
                productName: y.productName,
              });
            }
          // }
        });

        ids.forEach(y => {
          // define header for consume product
          const obj2 = {
            productId: y.productId,
            productCode: y.productCode,
            productName: y.productName,
            batchs: x.consumeProducts.filter(z => z.batchId && z.productId === y.productId) // select only match product
          };
          obj.consumeProducts.push(obj2);
        });

        // show
        this.form.displayProduced.push(obj);
      });
      // set key for each row
      this.form.displayProduced.forEach(x => {
        x.consumeProducts.forEach(y => {
          y.key = y.productId;
          y.batchs.forEach((z, k) => {
            z.key = k;
          });
        });
      });
      this.fetchImage();
    },
    setDisplayProduced(): void {
      this.form.displayProduced = this.detailJobCosting.consume.map((x, i) => {
        return {
          key: i,
          ...x,
          processInfo: x.processInfo.map(y => ({...y, thumbnail: null})),

          // no need to map consume products
          // cause it will filled after choose from modal
          consumeProducts: [],

          // consumeProducts: x.consumeProducts.map((y, j) => {
          //   return {
          //     id: y.id,
          //     key: j,
          //     productCode: y.productCode,
          //     productName: y.productName,
          //     productId: y.productId,
          //     locationId: y.locationId,
          //     locationName: y.locationName,
          //     batchs: [],
          //     listBatchPerConsume: [],
          //   };
          // }),

          // collect all parent product from quotation
          parentProducts: x.consumeProducts.filter((y) => y.parent),

          listBatch: [],
        };
      });
      this.fetchImage();
    },
    fetchImage(): void {
      this.form.displayProduced.forEach(x => {
        x.processInfo.forEach(y => {
          if (y.image) {
            this.getImage(y.image, (e) => {
              y.thumbnail = e;
            });
          }
        });
      });
    },
    onSaveBatch({ value }: { value: ResponseInventoryLineBatch[] }): void {
      let { consumeProducts } = this.form.displayProduced[this.dtModal.idxProduce];
      const prod = consumeProducts.find(x => x.productId === this.dtModal.productId);
      if (prod) {
        // const [invalid, bn] = this.hasDuplicateBNConsume(value);
        // if (invalid) {
        //   this.showNotifWarning("notif_duplicate_item", { data: bn });
        // } else {
        //   prod.batchs = value.map(x => ({
        //     key: x.batchId,
        //     ...x
        //   }));
        //   prod.listBatchPerConsume = value.map(x => ({
        //     batchId: x.batchId,
        //     id: null,
        //     locationId: x.warehouseLocationId,
        //     productId: x.product.id,
        //     qty: x.available,
        //     uomId: x.uom.id
        //   }));
        // }

        prod.batchs = value.map(x => ({
          key: x.batchId,
          ...x
        }));

        /**
         * all consume product doesnt have
         * consume id. because all consume product
         * is choose from process warehouse pick.
         * thats why all id is null from here
         */
        prod.listBatchPerConsume = value.map(x => ({
          batchId: x.batchId,
          id: null,
          locationId: x.warehouseLocationId,
          productId: x.product.id,
          qty: x.available,
          uomId: x.uom.id
        }));

        // map consume product id to product on V1
        // const prod2 = prod.listBatchPerConsume.find(x => x.productId === this.dtModal.productId);
        // if (prod2) {
        //   prod2.id = this.dtModal.id;
        // }
      }
    },
    hasDuplicateBNConsume(e): [boolean, string | null] {
      const ids: string[] = e.map(x => x.batchId);
      for (const x of this.form.displayProduced) {
        for (const y of x.consumeProducts) {
          for (const z of y.batchs) {
            if (ids.includes(z.batchId)) {
              return [true, z.batchNumber];
            }
          }
        }
      }
      return [false, null];
    },
    async getImage(filename: string | null, callback: (e) => void): Promise<void> {
      try {
        if (!filename) return;
        const res = await fileServices.getFile(filename);
        const img = await transformDataURL(res);
        callback(img);
      } catch (error) {
        this.showErrorMessage("notif_file_get_fail", { filename });
      }
    },
    showModalChooseConsume(record, item): void {
      this.vmModal.consume = true;
      this.dtModalConsume = { parent: record, produce: item, };
    },
    onSaveChooseConsume({ data }): void {
      const prod = this.form.displayProduced.find(x => x.id === this.dtModalConsume.produce.id);
      if (!prod) return;
      const consume = prod.consumeProducts.find(x => x.productId === data.productId);
      if (consume) {
        this.showNotifWarning("notif_duplicate_item");
        return;
      }
      prod.consumeProducts.push({
        id: "",
        key: data.productId,
        productCode: data.productCode,
        productName: data.productName,
        productId: data.productId,
        locationId: null,
        locationName: null,
        batchs: [],
        listBatchPerConsume: [],
      });
    },
    deleteConsume(record: IConsumeProduct, idxProduce: number, idxConsume: number): void {
      const newConsume = this.form.displayProduced[idxProduce].consumeProducts.filter(x => x.productId !== record.productId);
      this.form.displayProduced[idxProduce].consumeProducts = newConsume;
    },
    getTotalQtyBatch(e): string | number {
      let qty: number[] = [];
      if (this.isQuotationSubmitted) {
        qty = e.map(x => x.available ?? 0);
      } else {
        qty = e.map(x => x.qty ?? 0);
      }
      const sum = qty.reduce((a, b) => {
        if (b !== null && b !== undefined) {
          return new Decimal(b).plus(a).toNumber();
        }
        return 0;
      }, 0);
      return formatDecimalQty(sum);
    },
    deleteBn(batchs, record, listBatchPerConsume): void {
      const idx = batchs.findIndex(x => x.batchId === record.batchId);
      const idx2 = listBatchPerConsume.findIndex(x => x.batchId === record.batchId);
      if (idx === -1) return;
      if (idx2 === -1) return;
      batchs.splice(idx, 1);
      listBatchPerConsume.splice(idx2, 1);
    },
    async confirmCancel(): Promise<void> {
      const confirm: boolean = await this.showConfirmation();
      if (!confirm) return;
      this.cancelDocument();
    },
    async cancelDocument(): Promise<void> {
      try {
        this.loading.cancel = true;
        await jobCostingService.cancelWarehousePick(this.jobCostingId);
        this.showNotifSuccess("notif_cancel_success");
        this.$router.replace({ name: "sales.transactionsales.jobcosting.warehousepicked.create", params: { id: this.jobCostingId } });
      } catch (error) {
        this.showNotifError("notif_cancel_fail");
      } finally {
        this.loading.cancel = false;
      }
    }
  },
});
