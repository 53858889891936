var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_warehouse_picked") } },
        [
          _c(
            "span",
            { attrs: { slot: "extra" }, slot: "extra" },
            [
              _vm._v(" " + _vm._s(_vm.form.rfqNumber || "-") + " "),
              _c("a-divider", { attrs: { type: "vertical" } }),
              _vm._v(" " + _vm._s(_vm.form.status || "-") + " ")
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 8] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-form-model",
                    {
                      ref: "formWarehousePick",
                      attrs: {
                        "label-align": "left",
                        model: _vm.form,
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 8] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: { label: _vm.$t("lbl_customer_name") }
                                },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(_vm._s(_vm.form.customerName || "-"))
                                  ])
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_customer_po_number")
                                  }
                                },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(_vm._s(_vm.form.customerPo || "-"))
                                  ])
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_customer_order_date")
                                  }
                                },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          .moment(_vm.form.orderDate)
                                          .format(_vm.DEFAULT_DATE_FORMAT) ||
                                          "-"
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_eta_date") } },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          .moment(_vm.form.etaDate)
                                          .format(_vm.DEFAULT_DATE_FORMAT) ||
                                          "-"
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_eta_hour") } },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          .moment(_vm.form.etaDate)
                                          .format(_vm.DEFAULT_TIME_FORMAT) ||
                                          "-"
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_branch") } },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(_vm._s(_vm.form.branchName || "-"))
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_sales_person_name")
                                  }
                                },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(_vm._s(_vm.form.salesName || "-"))
                                  ])
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_operator_name"),
                                    prop: "operatorName"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(_vm._s(_vm.form.operatorName || "-"))
                                  ])
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_notes") } },
                                [
                                  _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(_vm._s(_vm.form.notes || "-"))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider", { attrs: { orientation: "left" } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_list_produced_products")) + " ")
          ]),
          _c(
            "a-row",
            { attrs: { gutter: [16, 8] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-collapse",
                    { attrs: { accordion: "" } },
                    _vm._l(_vm.form.displayProduced, function(item, i) {
                      return _c(
                        "a-collapse-panel",
                        {
                          key: item.key,
                          attrs: {
                            header:
                              i + 1 + ". " + _vm.$t("lbl_produced_product")
                          }
                        },
                        [
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 8] } },
                            [
                              _c("a-col", { attrs: { span: 24 } }, [
                                _c(
                                  "p",
                                  { staticClass: "text-subtitle-1 mb-0" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("lbl_process_info")) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c("a-col", { attrs: { sm: 24, md: 8, lg: 6 } }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("lbl_invoice_description")) +
                                      ":"
                                  )
                                ]),
                                _c("p", { staticClass: "m-0" }, [
                                  _vm._v(" " + _vm._s(item.alias || "-") + " ")
                                ])
                              ]),
                              _c("a-col", { attrs: { sm: 24, md: 8, lg: 6 } }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lbl_qty_order")) + ":")
                                ]),
                                _c("p", { staticClass: "m-0" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("toDecimalQty")(item.qtyOrder)
                                      ) +
                                      " "
                                  )
                                ])
                              ]),
                              _c("a-col", { attrs: { sm: 24, md: 8, lg: 6 } }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("lbl_warehouse_notes")) + ":"
                                  )
                                ]),
                                _c("p", { staticClass: "m-0" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.warehouseNote || "-") +
                                      " "
                                  )
                                ])
                              ]),
                              _c("a-col", { attrs: { sm: 24, md: 8, lg: 6 } }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lbl_condition")) + ":")
                                ]),
                                _c("p", { staticClass: "m-0" }, [
                                  _vm._v(
                                    " " + _vm._s(item.condition || "-") + " "
                                  )
                                ])
                              ]),
                              _c("a-col", { attrs: { sm: 24, md: 8, lg: 6 } }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("lbl_invoice_weight")) + ":"
                                  )
                                ]),
                                _c("p", { staticClass: "m-0" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.invoiceWeight || "-") +
                                      " "
                                  )
                                ])
                              ]),
                              _c("a-col", { attrs: { sm: 24, md: 8, lg: 6 } }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lbl_process")) + ":")
                                ]),
                                _c(
                                  "p",
                                  { staticClass: "m-0" },
                                  [
                                    item.isProcessed
                                      ? _c("a-icon", {
                                          attrs: {
                                            type: "check-circle",
                                            theme: "twoTone",
                                            "two-tone-color": "#52c41a"
                                          }
                                        })
                                      : _c("a-icon", {
                                          attrs: {
                                            type: "close-circle",
                                            theme: "twoTone",
                                            "two-tone-color": "#f5222d"
                                          }
                                        })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-table",
                                    {
                                      attrs: {
                                        "data-source": item.processInfo,
                                        pagination: false,
                                        scroll: {
                                          y: 320,
                                          x: "calc(1000px + 50%)"
                                        },
                                        "row-key": function(r, i) {
                                          return i
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "cutting",
                                          attrs: { "data-index": "cutting" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_cutting"))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "size",
                                          attrs: { "data-index": "size" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          record.size || "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [_vm._v(_vm._s(_vm.$t("lbl_size")))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "vacuumOption",
                                          attrs: {
                                            "data-index": "vacuumOption"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record) {
                                                  return [
                                                    record.vacuumOption
                                                      ? _c("a-icon", {
                                                          attrs: {
                                                            type:
                                                              "check-circle",
                                                            theme: "twoTone",
                                                            "two-tone-color":
                                                              "#52c41a"
                                                          }
                                                        })
                                                      : _c("a-icon", {
                                                          attrs: {
                                                            type:
                                                              "close-circle",
                                                            theme: "twoTone",
                                                            "two-tone-color":
                                                              "#f5222d"
                                                          }
                                                        })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_use_vacuum"))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      item.vacuumOption
                                        ? _c(
                                            "a-table-column",
                                            {
                                              key: "vacuumType",
                                              attrs: {
                                                "data-index": "vacuumType"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "title" },
                                                  slot: "title"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("lbl_vacuum_type")
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "trayOption",
                                          attrs: { "data-index": "trayOption" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record) {
                                                  return [
                                                    record.trayOption
                                                      ? _c("a-icon", {
                                                          attrs: {
                                                            type:
                                                              "check-circle",
                                                            theme: "twoTone",
                                                            "two-tone-color":
                                                              "#52c41a"
                                                          }
                                                        })
                                                      : _c("a-icon", {
                                                          attrs: {
                                                            type:
                                                              "close-circle",
                                                            theme: "twoTone",
                                                            "two-tone-color":
                                                              "#f5222d"
                                                          }
                                                        })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_use_tray"))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      item.trayOption
                                        ? _c(
                                            "a-table-column",
                                            {
                                              key: "trayType",
                                              attrs: {
                                                "data-index": "trayType"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "title" },
                                                  slot: "title"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("lbl_tray_type")
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "packQty",
                                          attrs: { "data-index": "packQty" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_qty_pack"))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "sticker",
                                          attrs: { "data-index": "sticker" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_sticker"))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "processNote",
                                          attrs: {
                                            "data-index": "processNote"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          record.processNote ||
                                                            "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_process_notes")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "image",
                                          attrs: {
                                            "data-index": "image",
                                            align: "center"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record) {
                                                  return [
                                                    record.image
                                                      ? _c("figure", [
                                                          _c("img", {
                                                            attrs: {
                                                              src:
                                                                record.thumbnail,
                                                              alt: text,
                                                              width: "150px",
                                                              height: "auto"
                                                            }
                                                          })
                                                        ])
                                                      : _c(
                                                          "span",
                                                          [
                                                            _c("a-icon", {
                                                              attrs: {
                                                                type: "file"
                                                              }
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_file_not_found"
                                                                  )
                                                                )
                                                            )
                                                          ],
                                                          1
                                                        )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_image"))
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("a-divider"),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16] } },
                            [
                              _c("a-col", { attrs: { span: 24 } }, [
                                _c(
                                  "p",
                                  { staticClass: "text-subtitle-1 mb-0" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_products")) + " "
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-table",
                                    {
                                      attrs: {
                                        "data-source": item.parentProducts,
                                        pagination: false,
                                        scroll: { y: 320 },
                                        "row-key": function(r, i) {
                                          return i
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "productCode",
                                          attrs: { "data-index": "productCode" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_product_code")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "productName",
                                          attrs: { "data-index": "productName" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_product_name")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      !_vm.isModeView
                                        ? _c(
                                            "a-table-column",
                                            {
                                              key: "actions",
                                              attrs: { align: "center" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(text, record) {
                                                      return [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              icon: "plus",
                                                              type: "primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showModalChooseConsume(
                                                                  record,
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_add_consume"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "title" },
                                                  slot: "title"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("lbl_action"))
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("a-divider"),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 8], type: "flex" } },
                            [
                              _c("a-col", { attrs: { span: 12 } }, [
                                _c(
                                  "p",
                                  { staticClass: "text-subtitle-1 mb-0" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("lbl_consumed_product")) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              item.consumeProducts.length
                                ? _vm._l(item.consumeProducts, function(
                                    inner,
                                    j
                                  ) {
                                    return _c(
                                      "a-col",
                                      { key: inner.key, attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-card",
                                          {
                                            attrs: {
                                              size: "small",
                                              title:
                                                _vm.$t("lbl_consumed_product") +
                                                ": " +
                                                (j + 1)
                                            }
                                          },
                                          [
                                            _c("a-badge", {
                                              attrs: {
                                                slot: "extra",
                                                count: _vm.getTotalQtyBatch(
                                                  inner.batchs
                                                ),
                                                "number-style": {
                                                  backgroundColor: "#52c41a"
                                                },
                                                "overflow-count": 9999999
                                              },
                                              slot: "extra"
                                            }),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_product_code") +
                                                    ": " +
                                                    (inner.productCode || "-")
                                                )
                                              )
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_product_name") +
                                                    ": " +
                                                    (inner.productName || "-")
                                                )
                                              )
                                            ]),
                                            _c("a-divider"),
                                            _c(
                                              "a-table",
                                              {
                                                attrs: {
                                                  "data-source": inner.batchs,
                                                  pagination: {
                                                    showTotal: function(total) {
                                                      return _vm.$t(
                                                        "lbl_total_items",
                                                        { total: total }
                                                      )
                                                    }
                                                  },
                                                  scroll: { y: 420 }
                                                }
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "footer" },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "lbl_total_selected_bn"
                                                          )
                                                        ) +
                                                          ": " +
                                                          _vm._s(
                                                            inner.batchs
                                                              .length || 0
                                                          )
                                                      )
                                                    ]),
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "lbl_total_qty_available",
                                                                {
                                                                  data: _vm.getTotalQtyBatch(
                                                                    inner.batchs
                                                                  )
                                                                }
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "a-table-column",
                                                  {
                                                    key: "no",
                                                    attrs: { width: "90px" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            text,
                                                            record,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "span",
                                                              {},
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    index + 1
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "title"
                                                        },
                                                        slot: "title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "lbl_number_short"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "a-table-column",
                                                  {
                                                    key: "batchNumber",
                                                    attrs: {
                                                      "data-index":
                                                        "batchNumber"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(
                                                            text,
                                                            record
                                                          ) {
                                                            return [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    record.batchNumber ||
                                                                      "-"
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "title"
                                                        },
                                                        slot: "title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "lbl_batch_number"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm.isQuotationSubmitted
                                                  ? [
                                                      _c(
                                                        "a-table-column",
                                                        {
                                                          key:
                                                            "warehouseLocationName",
                                                          attrs: {
                                                            "data-index":
                                                              "warehouseLocationName"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  text,
                                                                  record
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          record.warehouseLocationName ||
                                                                            "-"
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "title"
                                                              },
                                                              slot: "title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_location"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-table-column",
                                                        {
                                                          key: "available",
                                                          attrs: {
                                                            "data-index":
                                                              "available"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  text,
                                                                  record
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "toDecimalQty"
                                                                          )(
                                                                            record.available
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "title"
                                                              },
                                                              slot: "title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_qty_available"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-table-column",
                                                        {
                                                          key: "action",
                                                          attrs: {
                                                            align: "center"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  text,
                                                                  record
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "a-icon",
                                                                      {
                                                                        staticClass:
                                                                          "icon-delete",
                                                                        attrs: {
                                                                          type:
                                                                            "delete",
                                                                          theme:
                                                                            "filled"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.deleteBn(
                                                                              inner.batchs,
                                                                              record,
                                                                              inner.listBatchPerConsume
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "title"
                                                              },
                                                              slot: "title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_action"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : [
                                                      _c(
                                                        "a-table-column",
                                                        {
                                                          key: "locationName",
                                                          attrs: {
                                                            "data-index":
                                                              "locationName"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  text,
                                                                  record
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          record.locationName ||
                                                                            "-"
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "title"
                                                              },
                                                              slot: "title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_location"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-table-column",
                                                        {
                                                          key: "packDate",
                                                          attrs: {
                                                            "data-index":
                                                              "packDate"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  text,
                                                                  record
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "date"
                                                                          )(
                                                                            record.packDate
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "title"
                                                              },
                                                              slot: "title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_pack_date"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-table-column",
                                                        {
                                                          key: "qty",
                                                          attrs: {
                                                            "data-index": "qty"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  text,
                                                                  record
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "toDecimalQty"
                                                                          )(
                                                                            record.qty
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                slot: "title"
                                                              },
                                                              slot: "title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_qty_available"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                              ],
                                              2
                                            ),
                                            _vm.isQuotationSubmitted
                                              ? _c(
                                                  "template",
                                                  {
                                                    staticClass:
                                                      "ant-card-actions",
                                                    slot: "actions"
                                                  },
                                                  [
                                                    _c("a-icon", {
                                                      key: "delete",
                                                      staticClass:
                                                        "icon-delete",
                                                      attrs: {
                                                        type: "delete",
                                                        theme: "filled"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteConsume(
                                                            inner,
                                                            i,
                                                            j
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _c(
                                                      "a-button",
                                                      {
                                                        key: "add",
                                                        attrs: { icon: "plus" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.showModalBatch(
                                                              inner,
                                                              i
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "lbl_batch_number"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  })
                                : _c(
                                    "a-col",
                                    { attrs: { span: 24 } },
                                    [_c("a-empty")],
                                    1
                                  )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-row",
            { attrs: { gutter: [16, 8] } },
            [
              _c(
                "a-col",
                { attrs: { align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _vm.allowCancel
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "danger",
                                loading: _vm.loading.cancel,
                                icon: "close"
                              },
                              on: { click: _vm.confirmCancel }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                          )
                        : _vm._e(),
                      !_vm.isModeView
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "save",
                                loading: _vm.loading.submit
                              },
                              on: { click: _vm.handleSubmit }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.vmModal.batch
        ? _c("ModalLocationBatch", {
            attrs: {
              "prop-product-code": _vm.dtModal.productCode,
              "prop-default": _vm.dtModal
            },
            on: { "on-save": _vm.onSaveBatch },
            model: {
              value: _vm.vmModal.batch,
              callback: function($$v) {
                _vm.$set(_vm.vmModal, "batch", $$v)
              },
              expression: "vmModal.batch"
            }
          })
        : _vm._e(),
      _c("ModalChooseConsumeProduct", {
        attrs: { "prop-product-code": _vm.dtModalConsume.parent.productCode },
        on: { "on-save": _vm.onSaveChooseConsume },
        model: {
          value: _vm.vmModal.consume,
          callback: function($$v) {
            _vm.$set(_vm.vmModal, "consume", $$v)
          },
          expression: "vmModal.consume"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }